import React from 'react';

function ShippingPolicy() {
  return (
    <div className='container py-4'>
      <h2 className='my-3'>Paysants  - Shipping Policy</h2>
      <p>
        At Paysants , we understand that timely and reliable shipping is crucial when it comes to delivering quality products directly to your doorstep. Our Shipping Policy is designed to provide you with a transparent and seamless experience, ensuring that your eagerly awaited Paysants  products reach you in pristine condition.
      </p>

      <h4>Shipping Partners and Coverage</h4>
      <p>
        Paysants  collaborates with trusted shipping partners to offer a comprehensive and efficient delivery network. Whether you're located in a major city or a remote area, our shipping services extend across a wide geographic range. We are committed to bringing the Paysants  experience to customers worldwide.
      </p>

      <h4>Processing Time</h4>
      <p>
        Upon placing your order with Paysants , our dedicated team promptly begins processing. We strive to dispatch your products quickly, with an average delivery time of 5-7 business working days. However, Processing  times may vary during peak seasons or promotional events.
      </p>

      <h4>Shipping Options</h4>
      <p>
        Paysants  offers various shipping options to meet your needs. Standard shipping provides a cost-effective, reliable option, while expedited services are available for quicker delivery. Each shipping method is chosen with the utmost care to ensure the safe transit of your Paysants  products.
      </p>

      <h4>Estimated Delivery Times</h4>
      <p>
        Paysants  is committed to providing realistic and accurate estimated delivery times, which vary based on your location, selected shipping method, and any unforeseen circumstances. Estimated delivery times are displayed during checkout, helping you make an informed decision.
      </p>

      <h4>Order Tracking</h4>
      <p>
        We know how exciting it is to await your Paysants  products. Once your order is dispatched, we provide a tracking number via email. You can monitor your package's journey in real-time, giving you peace of mind as your Paysants  items make their way to you.
      </p>

      <h4>International Shipping</h4>
      <p>
        Paysants  serves a global customer base, and our international shipping services are tailored to meet the needs of customers worldwide. International shipping times may vary depending on customs processes and local regulations, but rest assured, Paysants  adheres to all international shipping guidelines to ensure smooth delivery.
      </p>

      <h4>Shipping Costs</h4>
      <p>
        Paysants  strives to maintain fair and competitive shipping costs. Shipping fees are calculated based on the weight of your order, your chosen shipping method, and your delivery location. During checkout, you'll see the shipping costs before finalizing your purchase.
      </p>

      <h4>Address Accuracy</h4>
      <p>
        Providing accurate and complete shipping information is crucial for a seamless delivery process. Paysants  cannot be held responsible for delays or issues arising from inaccurate address details. Please double-check your shipping information before confirming your order to ensure a smooth delivery.
      </p>

      <h4>Shipping Updates and Notifications</h4>
      <p>
        Communication is key at Paysants . You'll receive confirmation emails upon order placement, shipping confirmation with tracking information, and delivery updates to keep you informed every step of the way.
      </p>
    </div>
  );
}

export default ShippingPolicy;
