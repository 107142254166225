import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { DLT, ADD, REMOVE } from "../redux/actions/action";
import array from "./CardsData.js";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { NavLink , Link } from "react-router-dom";

function Product_details() {
  const [data, setData] = useState([]);
  const [show, setShow] = useState(false); // State to control modal visibility
  const [modalContent, setModalContent] = useState(null); // State to hold product details for modal

  const { id } = useParams();
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const getdata = useSelector((state) => state.cartreducer.carts);

  // Function to filter product details based on id
  const compare = () => {
    let comparedata = array.filter((e) => {
      return e.id == id;
    });
    setData(comparedata);
  };

  // Show product details in modal
  const handleShow = (product) => {
    setModalContent(product); // Set modal content to the selected product
    setShow(true); // Show the modal
  };

  const handleClose = () => setShow(false); // Close modal

  // Add product to cart
  const send = (e) => {
    dispatch(ADD(e));
    handleShow(e); // Open modal with product details after adding to cart
  };

  // Delete product
  const dlt = (id) => {
    dispatch(DLT(id));
    navigate("/");
  };

  // Remove one product
  const remove = (item) => {
    dispatch(REMOVE(item));
  };

  useEffect(() => {
    compare();
  }, [id]);

  return (
    <>
      <div className="container my-2 p-2">
        <h3 className="text-center p-3" style={{ fontWeight: '500', fontFamily: 'Montserrat;' }}>
          Item Details Page
        </h3>
        <hr />
        <section className="container mt-3 ">
          <div className="container-fluid">
            {data.map((ele) => {
              return (
                <div key={ele.id} className="row">
                  <div className="col-sm-6">
                    <div className="container">
                      <img
                        style={{ boxShadow: '3px 3px 3px 6px grey', borderRadius: '5px' }}
                        className='img-fluid mb-3 mt-3'
                        src={ele.imgdata}
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="container">
                      <h3><strong>{ele.rname}</strong></h3>
                      <h4><strong>Details</strong></h4>
                      <ul>
                        {ele.somedata.split(",").map((item, index) => (
                          <li key={index}>{item}</li>
                        ))}
                      </ul>
                      <strong>Description: </strong>
                      <p>
                        Our product combines quality craftsmanship with modern design, making it the perfect choice for your everyday needs. Whether you’re looking for reliability, style, or value, this Product delivers all three. It's made to enhance your lifestyle without compromising on comfort or durability.
                      </p>
                      <strong>Price: </strong>₹{ele.price}
                    </div>
                    <div className="row mt-3">
                      <div className="col-6">
                        <button className="button" onClick={() => send(ele)}>
                          <span className="button_lg">
                            <span className="button_sl"></span>
                            <span className="button_text">Add to cart!</span>
                          </span>
                        </button>
                      </div>
                      <div className="col-6">
                        <Link to="/product_details">
                          <button className="button">
                            <span className="button_lg">
                              <span className="button_sl"></span>
                              <span className="button_text">Back</span>
                            </span>
                          </button>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </section>
      </div>

      {/* Modal for showing product details */}
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{modalContent?.rname}</Modal.Title> {/* Product name */}
        </Modal.Header>
        <Modal.Body>
          {modalContent && (
            <>
              <div className="d-flex justify-content-center">
                <img src={modalContent.imgdata} alt={modalContent.rname} style={{ width: '150px', height: '150px', borderRadius: '5px' }} />
              </div>
              <p className="mt-3"><strong>Price:</strong> ₹ {modalContent.price}</p>
              <p><strong>Description:</strong> {modalContent.somedata}</p>
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
        <NavLink to={'/forms'}>
            <button className="button mt-3">
              <span className="button_lg">
                <span className="button_sl"></span>
                <span className="button_text">Checkout now!</span>
              </span>
            </button>
          </NavLink>
          <button className="button" onClick={handleClose}>
            <span className="button_lg">
              <span className="button_sl"></span>
              <span className="button_text">Close</span>
            </span>
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default Product_details;
