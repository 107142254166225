// import L_Handle_set from "../img/bot1.avif";
// import Fat_Grip_For_Cupping from "../img/bot_plast.jpg";
// import Cone_Grip from "../img/bot_printed.jpg";
// import Wallet from "../img/wallet.jpg";
// import belt from "../img/belt.jpg";
// import watch_women from "../img/watch.jpg";
// import watch_men from "../img/watch_2.jpg";
// import wallet_women from "../img/wallet_women.jpg";
// import cardholder_1 from "../img/cardholder_1.jpg";
// import cardholder_2 from "../img/cardholder_2.jpg";
// import phonecase_1 from "../img/phonecase_1.jpg";
// import hand_1 from "../img/hand_1.jpg";
// import hand_2 from "../img/hand_2.jpg";
// import keyring_1 from "../img/keyring_1.jpg";
// import keyring_2 from "../img/keyring_2.jpg";
// import keyring_3 from "../img/keyring_3.jpg";
// import candle_1 from "../img/candle_2.jpg";
// import candle_2 from "../img/candle_3.jpg";
// import Cupping_Handle from "../img/Cupping_Handle.png";
// import Multi_Spinner from "../img/Multi_Spinner.png";
// import TRINGA_ECCENTRIC_SET from "../img/TRINGA_ECCENTRIC_SET.png";
// import Palm_Cupping_L_Handle from "../img/Palm_Cupping_L_Handle.png";
// import Loading_Pin from "../img/Loading_Pin.png";
// import Hand_Winder from "../img/Hand_Winder.png";
// import Tiranga_Eccentric_Grip from "../img/Tiranga_Eccentric_Grip.png";
// import Elliptical_Wrist_Ball from "../img/Elliptical_Wrist_Ball.png";
// import Premium_Table_Strap from "../img/Premium_Table_Strap.png";
// import Eccentric_Grip from "../img/Eccentric_Grip.png";
// import Professional_Wrist_Ball from "../img/Professional_Wrist_Ball.png";
// import Devon_Larrat_Cupping_Handles from "../img/Devon_Larrat_Cupping_Handles.png";
// import Pronation_Ring_with_Hook from "../img/Pronation_Ring_with_Hook.png";
// import Arm_Wrestling_Training_Strap from "../img/Arm_Wrestling_Training_Strap.png";
// import Stainless_Steel_15mm_C_Handle from "../img/Stainless_Steel_15mm_C_Handle.png";
// import Arm_Wrestling_Handles_Set from "../img/Arm_Wrestling_Handles_Set.png";
// import Claws_Arm_Wrestling_Table from "../img/Claws_Arm_Wrestling_Table.png";
// import Arm_Wrestling_Table_Pulley from "../img/Arm_Wrestling_Table_Pulley.png";
// import Professional_Training_Strap from "../img/Professional_Training_Strap.png";
// import Claws_Cotton_Denis_T_Shirt_Black from "../img/Claws_Cotton_Denis_T-Shirt_Black.png";
// import product1 from "../img/product-1.JPG";
// import cups_1 from "../img/cups_1.jpg";
// import cups_2 from "../img/cups_2.jpg";
// import cups_3 from "../img/cups_3.jpg";
// import cups_4 from "../img/cups_4.jpg";
// import cups_5 from "../img/cups_5.jpg";
// import bags_1 from "../img/bags_1.jpg";
// import bags_2 from "../img/bags_2.jpg";

// import bags_3 from "../img/bags_3.webp";

// import bags_4 from "../img/marvel.jpg";
// import light_1 from "../img/light_1.png";
// import light_2 from "../img/light_2.png";
// import light_3 from "../img/light_3.png";
// import light_4 from "../img/light_4.png";
// import light_5 from "../img/lights_5.png";
// import chair_1 from "../img/Chair_1.png";
// import chair_2 from "../img/chair_2.png";
// import chair_3 from "../img/chair_3.png";
// import chair_4 from "../img/chair_4.png";
// import table_1 from "../img/table_1.png";
// import table_2 from "../img/table_2.png";
// import table_3 from "../img/table_4.png";
// import bed1 from "../img/product/bed.jpg";
// import bed2 from "../img/product/bed15.jpg";

// import bed3 from "../img/product/bed2.jpg";
// import bed4 from "../img/product/bed3.jpg";
// import bed5 from "../img/product/bed4.jpg";
// import bed6 from "../img/product/bed5.jpg";
// import bed7 from "../img/product/bed6.jpg";
// import bed8 from "../img/product/bed7.jpg";
// import bed9 from "../img/product/bed8.jpg";
// import bed10 from "../img/product/bed9.jpg";
// import bed11 from "../img/product/bed10.jpg";
// import bed12 from "../img/product/bed11.jpg";
// import bed13 from "../img/product/bed12.jpg";
// import bed14 from "../img/product/bed13.jpg";
// import bed15 from "../img/product/bed14.jpg";
// import almari from "../img/product/almira.jpg";
// import almari1 from "../img/product/almira1.jpg";
// import almari2 from "../img/product/almira2.jpg";
// import almari3 from "../img/product/almira3.jpg";
// import almari4 from "../img/product/almira4.jpg";
// import almari5 from "../img/product/almira5.jpg";
// import almari6 from "../img/product/almira6.jpg";
// import almari7 from "../img/product/almira7.jpg";
// import almari8 from "../img/product/almira8.jpg";
// import almari9 from "../img/product/almira9.jpg";
// import almari10 from "../img/product/almira10.jpg";
// import gchair from "../img/product/chair1.jpg";
// import gchair2 from "../img/product/chair2.jpg";
// import gchair3 from "../img/product/chair3.jpg";

// import jhum from "../img/product/jhumar1.jpg"
// import jhum1 from "../img/product/jhumar2.jpg"
// import jhum2 from "../img/product/jhumar3.jpg"
// import jhum3 from "../img/product/jhumar4.jpg"
// import jhum4 from "../img/product/jhumar5.jpg"
// import jhum5 from "../img/product/jhumar6.jpg"
// import jhum6 from "../img/product/jhumar7.webp"
// import jhum7 from "../img/product/jhumar8.jpg"
// import jhum8 from "../img/product/jhumar9.jpg"
// import jhum9 from "../img/product/jhumar10.jpg"
// import jhum10 from "../img/product/jhumar11.jpg"
// import sofa1 from "../img/product/sofa1.jpg"
// import sofa2 from "../img/product/sofa2.jpg"
// import sofa3 from "../img/product/sofa3.jpg"
// import sofa4 from "../img/product/sofa4.jpg"
// import sofa5 from "../img/product/sofa15.jpg"
// import sofa6 from "../img/product/sofa6.jpg"
// import sofa7 from "../img/product/sofa7.jpg"
// import sofa8 from "../img/product/sofa8.jpg"
// import sofa9 from "../img/product/sofa9.jpg"
// import sofa10 from "../img/product/sofa12.jpg"
// import sofa15 from "../img/product/sofa13.jpg"
// import sofa11 from "../img/product/sofa14.jpg"
// import sofa12 from "../img/product/sofa15.jpg"
// import sofa13 from "../img/product/sofa16.jpg"
// import sofa14 from "../img/product/sofa17.jpg"




























// const Cardsdata = [
//     {
//         id: 1,
//         rname: " Plain Bottle",
//         imgdata: L_Handle_set,
//         somedata: "This sleek and durable plain bottle is perfect for everyday use, made from high-quality BPA-free materials, available in a 500ml capacity, featuring a leak-proof cap for secure storage, designed with a smooth matte finish for a modern look, lightweight and easy to carry on the go, ideal for water, juice, or other beverages, fits in most cup holders for convenience during travel, easy to clean and maintain, dishwasher-safe for hassle-free washing, available in multiple colors to suit your style, eco-friendly and reusable, helps reduce plastic waste for a sustainable future.",
//         price: 499,
//         rating: "3.8",
//         qnty:0,
//         category:"bottles",
//     },
//     {
//         id: 2,
//         rname: "Plastic Bottle",
//         imgdata: Fat_Grip_For_Cupping,
//         somedata: "The Plastic Bottle is made from durable, BPA-free materials, available in a 500ml capacity, featuring a leak-proof cap for secure storage, designed with a sleek finish for a modern look, lightweight and portable for on-the-go use, ideal for storing water, juice, or other beverages, fits comfortably in most cup holders, easy to clean and maintain, dishwasher-safe for convenience, available in various colors to match your style, eco-friendly and reusable, helping to reduce plastic waste and support sustainability.",
//         price: 599,
//         rating: "3.8",
//         qnty:0,
//         category:"bottles",
//     },
//     {
//         id: 3,
//         rname: "Printed Bottle",
//         imgdata: Cone_Grip,
//         somedata: "The Printed Bottle is crafted from durable, BPA-free materials, available in a 500ml capacity, featuring a secure leak-proof cap, adorned with stylish prints for a unique and eye-catching design, lightweight and portable for daily use, ideal for holding water, juice, or other beverages, fits in most standard cup holders, easy to clean and maintain, dishwasher-safe for hassle-free care, available in a variety of vibrant prints to suit your personality, eco-friendly and reusable, promoting sustainability by reducing single-use plastic waste.",
//         price: 599,
//         rating: "3.8",
//         qnty:0,
//         category:"bottles",
//     },
//     {
//         id: 4,
//         rname: "Wallet",
//         imgdata: Wallet,
//         somedata: "The Wallet is crafted from premium quality materials for durability, featuring a sleek and compact design for easy portability, equipped with multiple compartments to securely hold cards, cash, and IDs, designed with RFID-blocking technology to protect against electronic theft, available in a variety of colors and finishes to suit your style, lightweight yet spacious enough to carry all your essentials, features a secure snap or zip closure for added safety, easy to clean and maintain, ideal for everyday use or travel, eco-friendly and long-lasting, promoting sustainability by reducing the need for frequent replacements.",
//         price: 699,
//         rating: "3.8",
//         qnty:0,
//         category:"Equipment",
//     },
//     {
//         id: 5,
//         rname: "Belt",
//         imgdata: belt,
//         somedata: "The Belt is made from premium, durable materials for long-lasting use, featuring a sleek and minimalist design suitable for both casual and formal wear, equipped with an adjustable buckle for a comfortable and secure fit, available in multiple sizes and colors to complement various outfits, designed with reinforced stitching for added strength, lightweight and easy to wear, resistant to wear and tear for daily use, ideal for pairing with jeans, trousers, or dresses, easy to clean and maintain, eco-friendly and sustainably sourced, ensuring style with a lower environmental impact.",
//         price: 458,
//         rating: "4.0",
//         qnty:0,
//         category:"Equipment",
//     },
//     {
//         id: 6,
//         rname: "Women Watch",
//         imgdata: watch_women,
//         somedata: "The Watch is crafted with high-quality, durable materials, featuring a sleek and modern design that suits both casual and formal attire, equipped with a precise quartz movement for accurate timekeeping, available with an adjustable strap for a comfortable fit, designed with a water-resistant casing for added protection, lightweight and easy to wear throughout the day, comes with a scratch-resistant glass to maintain clarity and longevity, ideal for everyday use or special occasions, available in multiple colors and styles to match your personal taste, eco-friendly with energy-efficient mechanisms, promoting long-lasting performance and sustainability.",
//         price: 12999,
//         rating: "4.9",
//         qnty:0,
//         category:"watch",
//     },
//     {
//         id: 7,
//         rname: "Men Watch",
//         imgdata: watch_men,
//         somedata: "The Watch is crafted with high-quality, durable materials, featuring a sleek and modern design that suits both casual and formal attire, equipped with a precise quartz movement for accurate timekeeping, available with an adjustable strap for a comfortable fit, designed with a water-resistant casing for added protection, lightweight and easy to wear throughout the day, comes with a scratch-resistant glass to maintain clarity and longevity, ideal for everyday use or special occasions, available in multiple colors and styles to match your personal taste, eco-friendly with energy-efficient mechanisms, promoting long-lasting performance and sustainability.",
//         price: 10999,
//         rating: "5.0",
//         qnty:0,
//         category:"watch",
//     },
//     {
//         id: 8,
//         rname: "Women Wallet",
//         imgdata: wallet_women,
//         somedata: "The Women Wallet is crafted from premium, soft-touch materials for a luxurious feel, featuring a sleek and stylish design, equipped with multiple card slots, a zippered coin compartment, and spacious sections for cash and receipts, designed with RFID-blocking technology for added security, lightweight and compact, making it easy to carry in handbags or clutches, available in a variety of chic colors and patterns to match any outfit, secured with a smooth zipper or snap closure for safety, easy to clean and maintain, eco-friendly and durable, designed to last while promoting sustainability and reducing waste.",
//         price: 1599,
//         rating: "4.2",
//         qnty:0,
//         category:"wallet",
//     },
//     {
//         id: 9,
//         rname: "Card Holder",
//         imgdata: cardholder_1,
//         somedata: "The Card Holder is made from premium, durable materials for long-lasting use, featuring a slim and minimalist design for easy portability, equipped with multiple slots to securely hold your credit cards, IDs, and business cards, lightweight and compact, fitting comfortably in your pocket or bag, designed with RFID-blocking technology for enhanced security against electronic theft, available in a variety of stylish colors and finishes, easy to clean and maintain, ideal for daily use or travel, eco-friendly and reusable, promoting sustainability by reducing the need for bulky wallets.",
//         price: 699,
//         rating: "4.4",
//         qnty:0,
//         category:"Equipment",
//     },
//     {
//         id: 10,
//         rname: "Card Holder",
//         imgdata: cardholder_2,
//         somedata: "The Card Holder is made from premium, durable materials for long-lasting use, featuring a slim and minimalist design for easy portability, equipped with multiple slots to securely hold your credit cards, IDs, and business cards, lightweight and compact, fitting comfortably in your pocket or bag, designed with RFID-blocking technology for enhanced security against electronic theft, available in a variety of stylish colors and finishes, easy to clean and maintain, ideal for daily use or travel, eco-friendly and reusable, promoting sustainability by reducing the need for bulky wallets.",
//         price: 799,
//         rating: "4.8",
//         qnty:0,
//         category:"Equipment",
//     },
//     {
//         id: 11,
//         rname: "Phone case",
//         imgdata: phonecase_1,
//         somedata: "The Phone Case is crafted from high-quality, shock-absorbent materials for superior protection, featuring a slim and lightweight design that fits comfortably in your hand, designed to shield your phone from drops, scratches, and everyday wear and tear, equipped with precise cutouts for easy access to buttons, ports, and camera, available in a variety of colors and patterns to suit your style, offers a non-slip grip for added security, compatible with wireless charging, easy to clean and maintain, eco-friendly and reusable, designed to extend the life of your phone while reducing environmental impact.",
//         price: 199,
//         rating: "3.8",
//         qnty:0,
//         category:"Hankerchief",
//     },
//     {
//         id: 12,
//         rname: "Hankerchief",
//         imgdata: hand_1,
//         somedata: "The Handkerchief is made from soft, high-quality cotton fabric for comfort and durability, featuring a classic square design that is versatile for multiple uses, ideal for everyday carry or special occasions, available in a variety of colors and patterns to suit your personal style, lightweight and easy to fold for convenient storage in pockets or bags, designed to be reusable and machine washable for easy care, perfect for use as a pocket square, for personal hygiene, or as a stylish accessory, eco-friendly and sustainable, helping to reduce waste from disposable alternatives.",
//         price: 199,
//         rating: "3.8",
//         qnty:0,
//         category:"Hankerchief",
//     },
//     {
//         id: 13,
//         rname: "Hankerchief",
//         imgdata: hand_2,
//         somedata: "The Handkerchief is made from soft, high-quality cotton fabric for comfort and durability, featuring a classic square design that is versatile for multiple uses, ideal for everyday carry or special occasions, available in a variety of colors and patterns to suit your personal style, lightweight and easy to fold for convenient storage in pockets or bags, designed to be reusable and machine washable for easy care, perfect for use as a pocket square, for personal hygiene, or as a stylish accessory, eco-friendly and sustainable, helping to reduce waste from disposable alternatives.",
//         price: 199,
//         rating: "3.8",
//         qnty:0,
//         category:"Hankerchief",
//     },
//     {
//         id: 14,
//         rname: "Keyring",
//         imgdata: keyring_1,
//         somedata: "The Keyring is crafted from durable materials for long-lasting use, featuring a sleek and stylish design that adds a touch of personality to your keys, equipped with a secure clasp to keep your keys organized and easily accessible, lightweight and compact, making it perfect for everyday carry, available in a variety of colors and styles to match your personal taste, designed with an ergonomic shape for comfortable handling, ideal for attaching to bags, backpacks, or belt loops, easy to clean and maintain, eco-friendly and reusable, promoting sustainability by reducing the need for single-use plastic key holders.",
//         price: 4499,
//         rating: "3.8",
//         qnty:0,
//         category:"Equipment",
//     },
   
//     {
//         id: 15,
//         rname: "Keyring",
//         imgdata: keyring_2,
//         somedata: "The Keyring is crafted from durable materials for long-lasting use, featuring a sleek and stylish design that adds a touch of personality to your keys, equipped with a secure clasp to keep your keys organized and easily accessible, lightweight and compact, making it perfect for everyday carry, available in a variety of colors and styles to match your personal taste, designed with an ergonomic shape for comfortable handling, ideal for attaching to bags, backpacks, or belt loops, easy to clean and maintain, eco-friendly and reusable, promoting sustainability by reducing the need for single-use plastic key holders.",
//         price: 199,
//         rating: "3.8",
//         qnty:0,
//         category:"Equipment",
//     },
//     {
//         id: 16,
//         rname: "Rose Candle",
//         imgdata: candle_1,
//         somedata: "The Rose Candle is elegantly crafted from high-quality, eco-friendly wax for a clean and long-lasting burn, infused with a delightful rose fragrance that creates a calming and romantic atmosphere, featuring a stylish design that complements any décor, equipped with a sturdy wick for a steady flame, available in various sizes to suit different spaces, ideal for enhancing relaxation during baths, dinners, or special occasions, designed to burn evenly for maximum fragrance diffusion, easy to use and maintain, presented in a beautiful jar that can be reused after the candle has burned, eco-friendly and sustainable, promoting a greener lifestyle while adding a touch of elegance to your home.",
//         price: 299,
//         rating: "3.8",
//         qnty:0,
//         category:"Candle",
//     },
//     {
//         id: 17,
//         rname: "Lavendar Candle",
//         imgdata: candle_2,
//         somedata: "The Lavender Candle is expertly made from high-quality, eco-friendly wax for a clean and long-lasting burn, infused with soothing lavender essential oil that promotes relaxation and tranquility, featuring an elegant design that enhances any room’s décor, equipped with a sturdy wick for a consistent and steady flame, available in various sizes to fit different settings, perfect for creating a calming atmosphere during meditation, yoga, or unwinding after a long day, designed to burn evenly for maximum scent diffusion, easy to use and maintain, presented in a stylish jar that can be repurposed after the candle has burned, eco-friendly and sustainable, contributing to a greener lifestyle while adding a touch of serenity to your space.",
//         price: 899,
//         rating: "3.8",
//         qnty:0,
//         category:"Candle",
//     },
//     {
//         id: 18,
//         rname: "Keyring",
//         imgdata: keyring_3,
//         somedata: "The Keyring is crafted from durable materials for long-lasting use, featuring a stylish and functional design that keeps your keys organized and easily accessible, equipped with a secure clasp to prevent key loss, lightweight and compact, making it perfect for everyday carry in pockets or bags, available in a variety of colors and designs to match your personal style, designed with an ergonomic shape for comfortable handling, ideal for attaching to backpacks, belt loops, or purses, easy to clean and maintain, eco-friendly and reusable, promoting sustainability by reducing the need for single-use plastic key holders.",
//         price: 449,
//         rating: "3.8",
//         qnty:0,
//         category:"Merchandise",
//     },
//     {
//         id: 19,
//         rname: "Plain Cups",
//         imgdata: cups_1,
//         somedata: "The Plain Cups are made from high-quality, durable materials for everyday use, featuring a classic and minimalist design suitable for any beverage, available in various sizes to accommodate hot or cold drinks, lightweight and easy to handle, designed for stackable storage to save space in your kitchen, microwave and dishwasher safe for convenient cleaning and reheating, ideal for casual dining, entertaining, or office use, available in a range of colors to suit your style, eco-friendly and reusable, helping to reduce waste from disposable cups while providing a simple yet elegant addition to your tableware collection.",
//         price: 449,
//         rating: "3.8",
//         qnty:0,
//         category:"cups",
//     },
//     {
//         id: 20,
//         rname: "Printed cups",
//         imgdata: cups_2,
//         somedata: "The Printed Cups are crafted from high-quality, durable materials, featuring vibrant and eye-catching designs that add a splash of color to any beverage, available in various sizes to cater to both hot and cold drinks, lightweight and easy to handle for everyday use, designed for stackable storage to optimize space in your kitchen, microwave and dishwasher safe for hassle-free cleaning, perfect for parties, gatherings, or personal use, available in a wide range of prints and patterns to match your unique style, eco-friendly and reusable, promoting sustainability by reducing reliance on disposable cups while enhancing your drinkware collection.",
//         price: 699,
//         rating: "3.8",
//         qnty:0,
//         category:"cups",
//     },
//     {
//         id: 21,
//         rname: "Cup handle",
//         imgdata: cups_3,
//         somedata: "The Cup Handle is made from high-quality, durable materials designed for long-lasting use, featuring an ergonomic shape for a comfortable grip, compatible with various cup designs to enhance usability, easy to install and remove for versatile application, lightweight yet sturdy to ensure stability while handling hot or cold beverages, available in multiple colors and finishes to match your style, designed to provide additional support for those with limited hand strength, dishwasher safe for easy cleaning and maintenance, eco-friendly and sustainable, contributing to a greener lifestyle by reducing the need for single-use disposable cups.",
//         price: 799,
//         rating: "3.8",
//         qnty:0,
//         category:"cups",
//     },
//     {
//         id: 22,
//         rname: "Coffee cup",
//         imgdata: cups_4,
//         somedata: "The Coffee Cup is crafted from high-quality ceramic or stainless steel for durability and heat retention, featuring an ergonomic handle for a comfortable grip, designed with a classic shape to enhance your coffee-drinking experience, available in various sizes to accommodate different coffee servings, microwave and dishwasher safe for convenient use and cleaning, ideal for home, office, or café settings, adorned with elegant or fun designs to suit your personal style, lightweight yet sturdy for everyday use, eco-friendly and reusable, promoting sustainability by reducing the need for disposable coffee cups while elevating your coffee moments.",
//         price: 599,
//         rating: "3.8",
//         qnty:0,
//         category:"cups",
//     },
//     {
//         id: 23,
//         rname: "Casual Bag",
//         imgdata: bags_1,
//         somedata: "The Casual Bag is made from durable, high-quality materials for everyday use, featuring a stylish and versatile design suitable for various occasions, equipped with multiple compartments to keep your essentials organized, lightweight and comfortable to carry, available in a range of colors and patterns to match your personal style, designed with adjustable straps for a customizable fit, ideal for work, school, travel, or leisure activities, easy to clean and maintain, perfect for carrying books, laptops, or daily necessities, eco-friendly and reusable, promoting sustainability by reducing reliance on single-use bags while adding functionality to your wardrobe.",
//         price: 899,
//         rating: "3.8",
//         qnty:0,
//         category:"bags",
//     },
//     {
//         id: 24,
//         rname: "Printed Bags",
//         imgdata:  bags_3,
//         somedata: "The Printed Bags are crafted from high-quality, durable materials designed for everyday use, featuring vibrant and eye-catching prints that add personality to your style, available in various sizes and shapes to suit different needs, equipped with sturdy handles for easy carrying, lightweight and easy to fold for convenient storage, ideal for shopping, travel, or as reusable gift bags, designed to be easy to clean and maintain, perfect for making a statement while being environmentally friendly, promoting sustainability by reducing reliance on single-use plastic bags, and contributing to a greener lifestyle with a fashionable flair.",
//         price: 599,
//         rating: "3.8",
//         qnty:0,
//         category:"bags",
//     },
//     {
//         id: 25,
//         rname: "School Bag",
//         imgdata: bags_2,
//         somedata: "The School Bag is made from high-quality, durable materials designed to withstand daily wear and tear, featuring a spacious main compartment for books, notebooks, and supplies, equipped with multiple pockets for organized storage of smaller items like pens, calculators, and devices, designed with padded shoulder straps for comfort during long days at school, lightweight and easy to carry, available in various colors and designs to suit different styles, ideal for students of all ages, includes additional features like water bottle holders and adjustable straps for a customized fit, easy to clean and maintain, promoting sustainability with eco-friendly materials that reduce environmental impact.",
//         price: 999, 
//         rating: "3.8",
//         qnty:0,
//         category:"bags",
//     },
//     {
//         id: 26,
//         rname: "Marvel Bags",
//         imgdata: bags_4,
//         somedata: "The Marvel Bags are designed for fans of all ages, crafted from high-quality, durable materials to withstand everyday use, featuring iconic Marvel graphics and characters that add a fun and vibrant touch, available in various sizes and styles, including backpacks, tote bags, and messenger bags, equipped with multiple compartments for organized storage of books, supplies, and personal items, designed with comfortable straps for easy carrying, lightweight and portable, perfect for school, outings, or casual use, easy to clean and maintain, showcasing a blend of style and fandom while promoting sustainability with eco-friendly materials that help reduce environmental impact.",
//         price: 1229,
//         rating: "3.8",
//         qnty:0,
//         category:"bags",
//     },

//     {
//         id: 27,
//         rname: "Cup ",
//         imgdata: cups_5,
//         somedata: "The Cup is made from high-quality, durable materials suitable for everyday use, featuring a classic design that complements any beverage, available in various sizes to accommodate both hot and cold drinks, lightweight and easy to handle, designed for stackable storage to save space in your kitchen, microwave and dishwasher safe for convenient cleaning and reheating, ideal for casual dining, office use, or entertaining, available in a range of colors to suit your style, eco-friendly and reusable, helping to reduce waste from disposable cups while providing a simple yet elegant addition to your drinkware collection.",
//         price: 899,
//         rating: "3.8",
//         qnty:0,
//         category:"cups",
//     },
//     {
//         id: 28,
//         rname: "Chair ",
//         imgdata: chair_1,
//         somedata: "The Chair is crafted from high-quality materials for durability and stability, featuring an ergonomic design that provides comfort during extended use, available in various styles and colors to suit any décor, designed with a sturdy frame to support a range of weights, lightweight and easy to move, perfect for dining rooms, offices, or outdoor spaces, equipped with cushioned seating for added comfort, easy to clean and maintain, suitable for both casual and formal settings, eco-friendly and sustainably sourced materials to promote a greener lifestyle while enhancing your space.",
//         price: 19899,
//         rating: "3.8",
//         qnty:0,
//         category:"cups",
//     },
//     {
//         id: 28,
//         rname: "Sofa chair ",
//         imgdata: chair_2,
//         somedata: "The Sofa Chair is designed for ultimate comfort, crafted from high-quality materials for durability and support, featuring a plush seat and backrest for relaxation during extended sitting, available in various styles, colors, and fabrics to complement any interior décor, designed with sturdy construction to ensure stability and longevity, equipped with armrests for added comfort, ideal for living rooms, reading nooks, or offices, lightweight enough for easy rearrangement, easy to clean and maintain, offering a stylish yet functional addition to your home, eco-friendly and sustainably sourced materials to promote a greener lifestyle while enhancing your living space.",
//         price: 19999,
//         rating: "3.8",
//         qnty:0,
//         category:"cups",
//     },
//     {
//         id: 29,
//         rname: "Office chair ",
//         imgdata: chair_3,
//         somedata: "The Office Chair is designed for optimal comfort and support during long hours of work, crafted from high-quality materials for durability and stability, featuring an ergonomic design with adjustable height and lumbar support to promote good posture, equipped with smooth-rolling casters for easy mobility, available in various styles and colors to fit any office décor, designed with breathable fabric or mesh for improved airflow, ideal for home offices, corporate settings, or coworking spaces, easy to assemble and maintain, offering a blend of style and functionality, eco-friendly and sustainably sourced materials to contribute to a greener workplace.",
//         price: 1699,
//         rating: "3.8",
//         qnty:0,
//         category:"cups",
//     },
//     {
//         id: 30,
//         rname: "Study chair ",
//         imgdata: chair_4,
//         somedata: "The Study Chair is designed for comfort and focus, crafted from high-quality, durable materials for long-lasting use, featuring an ergonomic design that supports proper posture during extended study sessions, equipped with adjustable height options to fit various desk setups, available in a variety of styles and colors to complement any study space, lightweight and easy to move for flexibility in your workspace, designed with a cushioned seat and backrest for enhanced comfort, ideal for home studies, libraries, or student dorms, easy to clean and maintain, eco-friendly and sustainably sourced materials to promote a greener environment while enhancing your study area.",
//         price: 21999,
//         rating: "3.8",
//         qnty:0,
//         category:"cups",
//     },
   
//     {
//         id: 33,
//         rname: "Study Table ",
//         imgdata: table_1,
//         somedata: "The Study Table is crafted from high-quality, durable materials for long-lasting use, featuring a spacious surface to accommodate books, laptops, and study materials, designed with an ergonomic height for comfortable working and studying, available in various styles and finishes to match any décor, equipped with built-in storage options like drawers or shelves to keep supplies organized, ideal for home offices, student rooms, or dedicated study areas, lightweight yet sturdy for easy rearrangement, easy to clean and maintain, eco-friendly and sustainably sourced materials to promote a greener lifestyle while providing a functional workspace.",
//         price: 9999,
//         rating: "3.8",
//         qnty:0,
//         category:"cups",
//     },
//     {
//         id: 34,
//         rname: "White study table ",
//         imgdata: table_2,
//         somedata: "The White Study Table is elegantly crafted from high-quality, durable materials for long-lasting use, featuring a spacious and clean surface that accommodates books, laptops, and study materials, designed with an ergonomic height to ensure comfortable working and studying, available in a sleek, modern design that complements any décor, equipped with built-in storage options like drawers or shelves to keep supplies organized, ideal for home offices, student rooms, or dedicated study areas, lightweight yet sturdy for easy rearrangement, easy to clean and maintain, eco-friendly and sustainably sourced materials to promote a greener lifestyle while providing a stylish and functional workspace.",
//         price: 15999,
//         rating: "3.8",
//         qnty:0,
//         category:"cups",
//     },
//     {
//         id: 35,
//         rname: "Dinning table ",
//         imgdata: table_3,
//         somedata: "The Dining Table is crafted from high-quality, durable materials designed to withstand daily use, featuring a spacious surface that accommodates family meals and gatherings, available in various styles, shapes, and finishes to suit any dining room décor, designed with sturdy legs for stability and support, ideal for both casual and formal dining settings, easy to clean and maintain, accommodating a range of seating options from chairs to benches, lightweight enough for rearrangement but solid enough for long-term use, eco-friendly and sustainably sourced materials to promote a greener lifestyle while enhancing your dining experience.",
//         price: 28999,
//         rating: "3.8",
//         qnty:0,
//         category:"cups",
//     },
//     {
//         id: 36,
//         rname: "Seiling Lights ",
//         imgdata: light_1,
//         somedata: "The Ceiling Lights are designed to provide bright and efficient illumination for any space, crafted from high-quality materials for durability and style, available in various designs, including modern, classic, and decorative options to match any interior décor, equipped with energy-efficient LED bulbs for long-lasting performance and reduced energy consumption, easy to install with all necessary hardware included, ideal for living rooms, kitchens, dining areas, or hallways, designed to distribute light evenly throughout the room, lightweight yet sturdy for reliable support, eco-friendly and made from sustainable materials, enhancing your home while promoting a greener environment.",
//         price: 12999,
//         rating: "3.8",
//         qnty:0,
//         category:"lights",
//     },
//     {
//         id: 37,
//         rname: "Decorative Lights ",
//         imgdata: light_2,
//         somedata: "The Decorative Lights are designed to add a touch of charm and ambiance to any space, crafted from high-quality materials for durability and aesthetic appeal, featuring various styles, including string lights, fairy lights, and lanterns to suit different décor themes, equipped with energy-efficient LED bulbs for long-lasting illumination and reduced energy consumption, ideal for indoor and outdoor settings, perfect for parties, events, or creating a cozy atmosphere in living rooms, bedrooms, or patios, easy to install and use, available in a range of colors and designs to match your personal style, eco-friendly and sustainably made to enhance your environment while promoting a greener lifestyle.",
//         price: 7999,
//         rating: "3.8",
//         qnty:0,
//         category:"lights",
//     },
//     {
//         id: 38,
//         rname: "Seiling Lights ",
//         imgdata: light_3,
//         somedata: "The Ceiling Lights are designed to provide efficient and stylish illumination for any room, crafted from high-quality materials for durability and longevity, available in a variety of designs, including modern, vintage, and minimalist styles to complement any interior décor, equipped with energy-efficient LED bulbs for reduced energy consumption and extended lifespan, easy to install with all necessary mounting hardware included, ideal for living rooms, bedrooms, kitchens, or dining areas, designed to evenly distribute light throughout the space, lightweight yet sturdy to ensure reliable installation, eco-friendly and sustainably sourced materials, enhancing your home’s aesthetic while promoting a greener lifestyle.",
//         price: 17999,
//         rating: "3.8",
//         qnty:0,
//         category:"lights",
//     },
//     {
//         id: 39,
//         rname: "table Lights ",
//         imgdata: light_4,
//         somedata: "The Table Lights are designed to provide focused illumination while adding a stylish accent to any space, crafted from high-quality materials for durability and elegance, featuring a variety of designs, including modern, vintage, and decorative options to suit different décor themes, equipped with energy-efficient LED bulbs for long-lasting performance and reduced energy consumption, ideal for use on desks, bedside tables, or living room side tables, designed with adjustable brightness or multiple settings for customizable lighting, easy to operate with simple controls, available in various colors and finishes to match your personal style, eco-friendly and sustainably made, enhancing your environment while promoting a greener lifestyle.",
//         price: 5999,
//         rating: "3.8",
//         qnty:0,
//         category:"lights",
//     },
//     {
//         id: 40,
//         rname: "Lights ",
//         imgdata: light_5,
//         somedata: "The Lights are designed to provide versatile illumination for a variety of settings, crafted from high-quality materials for durability and style, available in a range of types including ceiling lights, table lamps, floor lamps, and decorative fixtures to suit different needs and preferences, equipped with energy-efficient LED technology for long-lasting performance and reduced energy consumption, ideal for indoor and outdoor use, designed to create ambiance and enhance the aesthetic of any space, easy to install and operate with user-friendly controls, available in various colors, shapes, and styles to match your décor, eco-friendly and sustainably sourced materials to promote a greener lifestyle while illuminating your home beautifully.",
//         price: 6999,
//         rating: "3.8",
//         qnty:0,
//         category:"lights",
//     },
//     {
//         id: 41,
//         rname: "King Bed",
//         imgdata: bed1 ,
//         somedata: "The King Bed is designed for ultimate comfort and spaciousness, crafted from high-quality materials for durability and support, featuring a robust frame that accommodates standard king-sized mattresses, available in various styles, including modern, traditional, and upholstered options to suit any bedroom décor, designed with sturdy slats or a solid foundation for optimal mattress support, ideal for couples or individuals who prefer extra sleeping space, equipped with options for built-in storage or headboards for added functionality, easy to assemble and maintain, eco-friendly and sustainably sourced materials to promote a greener lifestyle while enhancing your sleeping environment.",
//         price: 26999,
//         rating: "3.8",
//         qnty:0,
//         category:"beds",
//     },
//     {
//         id: 42,
//         rname: "Queen Bed",
//         imgdata: bed2 ,
//         somedata: "The Queen Bed is designed to offer a perfect balance of comfort and space, crafted from high-quality materials for durability and support, featuring a standard queen-sized frame that accommodates most mattresses, available in a variety of styles, including modern, classic, and upholstered designs to complement any bedroom décor, designed with sturdy slats or a solid base to ensure optimal mattress support, ideal for couples or individuals who enjoy extra room while sleeping, equipped with options for built-in storage or stylish headboards for added functionality, easy to assemble and maintain, eco-friendly and sustainably sourced materials to promote a greener lifestyle while enhancing your sleeping environment.",
//         price: 24999,
//         rating: "3.8",
//         qnty:0,
//         category:"beds",
//     },
//     {
//         id: 43,
//         rname: " Wooden Bed",
//         imgdata: bed3 ,
//         somedata: "The Wooden Bed is crafted from high-quality, durable wood for long-lasting strength and stability, featuring a timeless design that adds warmth and elegance to any bedroom décor, available in various styles, including contemporary, rustic, and traditional finishes to suit different aesthetics, designed with sturdy slats or a solid base for optimal mattress support, ideal for both single and shared sleeping spaces, equipped with options for built-in storage drawers or decorative headboards for added functionality, easy to assemble and maintain, eco-friendly and sustainably sourced materials to promote a greener lifestyle while enhancing your sleeping environment.",
//         price: 22999,
//         rating: "3.8",
//         qnty:0,
//         category:"beds",
//     },
//     {
//         id: 44,
//         rname: "Full Bed",
//         imgdata: bed4 ,
//         somedata: "The Full Bed is designed to provide a comfortable sleeping space for individuals or couples, crafted from high-quality materials for durability and support, featuring a standard full-sized frame that accommodates most mattresses, available in various styles, including modern, traditional, and upholstered options to complement any bedroom décor, designed with sturdy slats or a solid foundation to ensure optimal mattress support, ideal for smaller bedrooms or guest rooms where space is a consideration, equipped with options for built-in storage or stylish headboards for added functionality, easy to assemble and maintain, eco-friendly and sustainably sourced materials to promote a greener lifestyle while enhancing your sleeping environment.",
//         price: 31999,
//         rating: "3.8",
//         qnty:0,
//         category:"beds",
//     },
//     {
//         id: 45,
//         rname: "Cal King Bed",
//         imgdata: bed5 ,
//         somedata: "The Cal King Bed is designed to offer luxurious comfort and ample sleeping space, crafted from high-quality materials for durability and support, featuring a California king-sized frame that accommodates longer, narrower mattresses, available in a variety of styles, including contemporary, rustic, and upholstered designs to suit any bedroom décor, designed with sturdy slats or a solid foundation for optimal mattress support, ideal for taller individuals or couples who enjoy extra legroom, equipped with options for built-in storage solutions or elegant headboards for added functionality, easy to assemble and maintain, eco-friendly and sustainably sourced materials to promote a greener lifestyle while enhancing your sleeping environment.",
//         price: 26999,
//         rating: "3.8",
//         qnty:0,
//         category:"beds",
//     },
//     {
//         id: 46,
//         rname: "Water Bed",
//         imgdata: bed6 ,
//         somedata: "The Water Bed is designed to provide unique comfort and support, crafted from high-quality materials for durability and longevity, featuring a specialized water-filled mattress that conforms to the body's shape for customized support, available in various styles, including softside and hardside designs to suit different preferences, equipped with adjustable water levels to allow for personalized firmness, ideal for those seeking relief from pressure points and back pain, designed with a sturdy frame to ensure stability and safety, easy to set up and maintain with straightforward filling and draining processes, eco-friendly options available to promote a greener lifestyle while enhancing your sleep experience.",
//         price: 28999,
//         rating: "3.8",
//         qnty:0,
//         category:"beds",
//     },
//     {
//         id: 47,
//         rname: "Book Case Bed",
//         imgdata: bed7 ,
//         somedata: "The Book Case Bed is designed to maximize space and functionality in any bedroom, crafted from high-quality materials for durability and stability, featuring an integrated bookcase headboard that provides ample storage for books, decorative items, and personal belongings, available in various styles and finishes to complement any décor, designed with a sturdy frame that accommodates standard mattress sizes while ensuring optimal support, ideal for smaller spaces or for those who appreciate a tidy and organized environment, equipped with additional shelves or storage compartments for added functionality, easy to assemble and maintain, eco-friendly and sustainably sourced materials to promote a greener lifestyle while enhancing your sleeping space.",
//         price: 23999,
//         rating: "3.8",
//         qnty:0,
//         category:"beds",
//     },
//     {
//         id: 48,
//         rname: "Murphy Bed",
//         imgdata: bed8 ,
//         somedata: "The Murphy Bed is designed to provide space-saving solutions for small living areas, crafted from high-quality materials for durability and stability, featuring a foldable design that allows the bed to be easily stored upright against the wall when not in use, available in various styles and finishes to blend seamlessly with any décor, designed with a sturdy frame and mattress support system to ensure a comfortable sleeping experience, ideal for studio apartments, guest rooms, or multifunctional spaces, equipped with built-in shelves or cabinets for added storage options, easy to set up and operate with straightforward folding mechanisms, eco-friendly and sustainably sourced materials to promote a greener lifestyle while optimizing your living space.",
//         price: 28999,
//         rating: "3.8",
//         qnty:0,
//         category:"beds",
//     },
//     {
//         id: 49,
//         rname: "Divan Bed",
//         imgdata: bed9 ,
//         somedata: "The Divan Bed is designed for both comfort and style, crafted from high-quality materials for durability and support, featuring a low-profile base that provides a modern look while maximizing sleeping space, available in various styles, colors, and fabrics to complement any bedroom décor, designed with a sturdy construction that supports a wide range of mattresses, often equipped with built-in storage drawers or options for additional bedding storage to help keep your space organized, ideal for both small and spacious bedrooms, easy to assemble and maintain, eco-friendly and sustainably sourced materials to promote a greener lifestyle while enhancing your sleeping environment.",
//         price: 19999,
//         rating: "3.8",
//         qnty:0,
//         category:"beds",
//     },
//     {
//         id: 50,
//         rname: "Ottoman Bed",
//         imgdata: bed10 ,
//         somedata: "The Ottoman Bed is designed to combine comfort and functionality, crafted from high-quality materials for durability and long-lasting support, featuring a stylish frame that lifts to reveal spacious storage underneath, ideal for keeping bedding, clothes, or other items neatly tucked away, available in various styles, colors, and fabrics to complement any bedroom décor, designed to accommodate standard mattress sizes while providing a sturdy foundation, equipped with a hydraulic lift mechanism for easy access to storage, perfect for maximizing space in smaller bedrooms or guest rooms, easy to assemble and maintain, eco-friendly and sustainably sourced materials to promote a greener lifestyle while enhancing your sleeping environment.",
//         price: 29999,
//         rating: "3.8",
//         qnty:0,
//         category:"beds",
//     },
//     {
//         id: 51,
//         rname: "Panel Bed",
//         imgdata: bed11 ,
//         somedata: "The Panel Bed is designed to provide a classic and elegant look while offering sturdy support, crafted from high-quality materials for durability and longevity, featuring a beautifully designed headboard and footboard with a flat panel construction that adds a touch of sophistication to any bedroom décor, available in various styles, finishes, and sizes to suit different tastes and space requirements, designed with a robust frame to accommodate standard mattress sizes, ideal for both traditional and contemporary settings, easy to assemble and maintain, eco-friendly and sustainably sourced materials to promote a greener lifestyle while enhancing your sleeping environment.",
//         price: 34999,
//         rating: "3.8",
//         qnty:0,
//         category:"beds",
//     },
//     {
//         id: 52,
//         rname: "Upholstered Bed",
//         imgdata: bed12 ,
//         somedata: "The Upholstered Bed is designed to offer luxurious comfort and a stylish aesthetic, crafted from high-quality materials for durability and support, featuring a padded headboard and frame that are covered in soft fabric or leather for a sophisticated look, available in various colors, textures, and designs to complement any bedroom décor, designed with a sturdy frame to accommodate standard mattress sizes, ideal for creating a cozy and inviting atmosphere in your space, equipped with options for tufting or nailhead trim for added elegance, easy to assemble and maintain, eco-friendly and sustainably sourced materials to promote a greener lifestyle while enhancing your sleeping environment.",
//         price: 24999,
//         rating: "3.8",
//         qnty:0,
//         category:"beds",
//     },
//     {
//         id: 53,
//         rname: "Lighted Bed",
//         imgdata: bed13 ,
//         somedata: "The Lighted Bed is designed to provide a modern and innovative sleeping experience, crafted from high-quality materials for durability and support, featuring integrated LED lighting in the headboard or base that adds ambiance and functionality, available in various styles and finishes to complement any bedroom décor, designed with a sturdy frame to accommodate standard mattress sizes, ideal for creating a soothing atmosphere or providing soft illumination for nighttime reading, equipped with adjustable brightness settings or color options for personalized lighting, easy to assemble and maintain, eco-friendly and sustainably sourced materials to promote a greener lifestyle while enhancing your sleeping environment.",
//         price: 28999,
//         rating: "3.8",
//         qnty:0,
//         category:"beds",
//     },
//     {
//         id: 54,
//         rname: "Cot Bed",
//         imgdata: bed14 ,
//         somedata: "The Cot Bed is designed to provide a safe and comfortable sleeping space for infants and toddlers, crafted from high-quality, durable materials for long-lasting use, featuring adjustable mattress heights to accommodate growing children, available in various styles and finishes to complement any nursery décor, designed with sturdy construction to ensure safety and stability, often equipped with removable side rails for easy access and transition to a toddler bed, ideal for maximizing space in smaller rooms, easy to assemble and maintain, eco-friendly and sustainably sourced materials to promote a greener lifestyle while providing a cozy sleeping environment for your child.",
//         price: 25999,
//         rating: "3.8",
//         qnty:0,
//         category:"beds",
//     },
//     {
//         id: 55,
//         rname: "Traditional Bed",
//         imgdata: bed15 ,
//         somedata: "The Traditional Bed is designed to offer timeless elegance and comfort, crafted from high-quality materials for durability and support, featuring classic design elements such as ornate headboards, footboards, and intricate detailing that enhance the overall aesthetic of any bedroom décor, available in various finishes, including wood stains and painted options to suit different tastes, designed with a robust frame that accommodates standard mattress sizes, ideal for creating a warm and inviting atmosphere in your space, easy to assemble and maintain, eco-friendly and sustainably sourced materials to promote a greener lifestyle while enhancing your sleeping environment.",
//         price: 23999,
//         rating: "3.8",
//         qnty:0,
//         category:"beds",
//     },
//     {
//         id: 56,
//         rname: "Wooden Almiras",
//         imgdata: almari ,
//         somedata: "Wooden Almiras are designed to provide stylish and functional storage solutions for any room, crafted from high-quality, durable wood for long-lasting use, featuring spacious compartments and shelves that accommodate clothing, linens, and personal items, available in various designs, finishes, and styles to complement any home décor, designed with sturdy construction to ensure stability and reliability, often equipped with doors or drawers for easy access and organization, ideal for bedrooms, living rooms, or entryways, easy to assemble and maintain, eco-friendly and sustainably sourced materials to promote a greener lifestyle while enhancing your storage options.",
//         price: 17999,
//         rating: "3.8",
//         qnty:0,
//         category:"almari",
//     },
//     {
//         id: 57,
//         rname: "Wooden Almiras",
//         imgdata: almari1 ,
//         somedata: "Wooden Almiras are designed to provide stylish and functional storage solutions for any room, crafted from high-quality, durable wood for long-lasting use, featuring spacious compartments and shelves that accommodate clothing, linens, and personal items, available in various designs, finishes, and styles to complement any home décor, designed with sturdy construction to ensure stability and reliability, often equipped with doors or drawers for easy access and organization, ideal for bedrooms, living rooms, or entryways, easy to assemble and maintain, eco-friendly and sustainably sourced materials to promote a greener lifestyle while enhancing your storage options.",
//         price: 23999,
//         rating: "3.8",
//         qnty:0,
//         category:"almari",
//     },
//     {
//         id: 58,
//         rname: "Wooden Almiras",
//         imgdata: almari2 ,
//         somedata: "Wooden Almiras are designed to provide stylish and functional storage solutions for any room, crafted from high-quality, durable wood for long-lasting use, featuring spacious compartments and shelves that accommodate clothing, linens, and personal items, available in various designs, finishes, and styles to complement any home décor, designed with sturdy construction to ensure stability and reliability, often equipped with doors or drawers for easy access and organization, ideal for bedrooms, living rooms, or entryways, easy to assemble and maintain, eco-friendly and sustainably sourced materials to promote a greener lifestyle while enhancing your storage options.",
//         price: 25999,
//         rating: "3.8",
//         qnty:0,
//         category:"almari",
//     },
//     {
//         id: 58,
//         rname: "Wooden Almiras",
//         imgdata: almari3 ,
//         somedata: "Wooden Almiras are designed to provide stylish and functional storage solutions for any room, crafted from high-quality, durable wood for long-lasting use, featuring spacious compartments and shelves that accommodate clothing, linens, and personal items, available in various designs, finishes, and styles to complement any home décor, designed with sturdy construction to ensure stability and reliability, often equipped with doors or drawers for easy access and organization, ideal for bedrooms, living rooms, or entryways, easy to assemble and maintain, eco-friendly and sustainably sourced materials to promote a greener lifestyle while enhancing your storage options.",
//         price: 27999,
//         rating: "3.8",
//         qnty:0,
//         category:"almari",
//     },
//     {
//         id: 59,
//         rname: "Wooden Almiras",
//         imgdata: almari4 ,
//         somedata: "Wooden Almiras are designed to provide stylish and functional storage solutions for any room, crafted from high-quality, durable wood for long-lasting use, featuring spacious compartments and shelves that accommodate clothing, linens, and personal items, available in various designs, finishes, and styles to complement any home décor, designed with sturdy construction to ensure stability and reliability, often equipped with doors or drawers for easy access and organization, ideal for bedrooms, living rooms, or entryways, easy to assemble and maintain, eco-friendly and sustainably sourced materials to promote a greener lifestyle while enhancing your storage options.",
//         price: 19999,
//         rating: "3.8",
//         qnty:0,
//         category:"almari",
//     },
//     {
//         id: 60,
//         rname: "Wooden Almiras",
//         imgdata: almari5 ,
//         somedata: "Wooden Almiras are designed to provide stylish and functional storage solutions for any room, crafted from high-quality, durable wood for long-lasting use, featuring spacious compartments and shelves that accommodate clothing, linens, and personal items, available in various designs, finishes, and styles to complement any home décor, designed with sturdy construction to ensure stability and reliability, often equipped with doors or drawers for easy access and organization, ideal for bedrooms, living rooms, or entryways, easy to assemble and maintain, eco-friendly and sustainably sourced materials to promote a greener lifestyle while enhancing your storage options.",
//         price: 21999,
//         rating: "3.8",
//         qnty:0,
//         category:"almari",
//     },
//     {
//         id: 61,
//         rname: "Wooden Almiras",
//         imgdata: almari6 ,
//         somedata: "Wooden Almiras are designed to provide stylish and functional storage solutions for any room, crafted from high-quality, durable wood for long-lasting use, featuring spacious compartments and shelves that accommodate clothing, linens, and personal items, available in various designs, finishes, and styles to complement any home décor, designed with sturdy construction to ensure stability and reliability, often equipped with doors or drawers for easy access and organization, ideal for bedrooms, living rooms, or entryways, easy to assemble and maintain, eco-friendly and sustainably sourced materials to promote a greener lifestyle while enhancing your storage options.",
//         price: 24999,
//         rating: "3.8",
//         qnty:0,
//         category:"almari",
//     },
//     {
//         id: 62,
//         rname: "Wooden Almiras",
//         imgdata: almari7 ,
//         somedata: "Wooden Almiras are designed to provide stylish and functional storage solutions for any room, crafted from high-quality, durable wood for long-lasting use, featuring spacious compartments and shelves that accommodate clothing, linens, and personal items, available in various designs, finishes, and styles to complement any home décor, designed with sturdy construction to ensure stability and reliability, often equipped with doors or drawers for easy access and organization, ideal for bedrooms, living rooms, or entryways, easy to assemble and maintain, eco-friendly and sustainably sourced materials to promote a greener lifestyle while enhancing your storage options.",
//         price: 23999,
//         rating: "3.8",
//         qnty:0,
//         category:"almari",
//     },
//     {
//         id: 63,
//         rname: "Wooden Almiras",
//         imgdata: almari8 ,
//         somedata: "Wooden Almiras are designed to provide stylish and functional storage solutions for any room, crafted from high-quality, durable wood for long-lasting use, featuring spacious compartments and shelves that accommodate clothing, linens, and personal items, available in various designs, finishes, and styles to complement any home décor, designed with sturdy construction to ensure stability and reliability, often equipped with doors or drawers for easy access and organization, ideal for bedrooms, living rooms, or entryways, easy to assemble and maintain, eco-friendly and sustainably sourced materials to promote a greener lifestyle while enhancing your storage options.",
//         price: 28999,
//         rating: "3.8",
//         qnty:0,
//         category:"almari",
//     },
//     {
//         id: 64,
//         rname: "Wooden Almiras",
//         imgdata: almari9 ,
//         somedata: "Wooden Almiras are designed to provide stylish and functional storage solutions for any room, crafted from high-quality, durable wood for long-lasting use, featuring spacious compartments and shelves that accommodate clothing, linens, and personal items, available in various designs, finishes, and styles to complement any home décor, designed with sturdy construction to ensure stability and reliability, often equipped with doors or drawers for easy access and organization, ideal for bedrooms, living rooms, or entryways, easy to assemble and maintain, eco-friendly and sustainably sourced materials to promote a greener lifestyle while enhancing your storage options.",
//         price: 23999,
//         rating: "3.8",
//         qnty:0,
//         category:"almari",
//     },
//     {
//         id: 65,
//         rname: "Wooden Almiras",
//         imgdata: almari10 ,
//         somedata: "Wooden Almiras are designed to provide stylish and functional storage solutions for any room, crafted from high-quality, durable wood for long-lasting use, featuring spacious compartments and shelves that accommodate clothing, linens, and personal items, available in various designs, finishes, and styles to complement any home décor, designed with sturdy construction to ensure stability and reliability, often equipped with doors or drawers for easy access and organization, ideal for bedrooms, living rooms, or entryways, easy to assemble and maintain, eco-friendly and sustainably sourced materials to promote a greener lifestyle while enhancing your storage options.",
//         price: 22999,
//         rating: "3.8",
//         qnty:0,
//         category:"almari",
//     },
//     {
//         id: 66,
//         rname: "Gaming Chair",
//         imgdata: gchair ,
//         somedata: "Our combines quality craftsmanship with modern design, making it the perfect choice for your everyday needs. Whether you’re looking for reliability, style, or value, this Product delivers all three.  it’s made to enhance your lifestyle without compromising on comfort or durability.",
//         price: 21999,
//         rating: "3.8",
//         qnty:0,
//         category:"Chair",
//     },
//     {
//         id: 67,
//         rname: "Gaming Chair",
//         imgdata: gchair2 ,
//         somedata: "Our combines quality craftsmanship with modern design, making it the perfect choice for your everyday needs. Whether you’re looking for reliability, style, or value, this Product delivers all three.  it’s made to enhance your lifestyle without compromising on comfort or durability.",
//         price: 19999,
//         rating: "3.8",
//         qnty:0,
//         category:"Chair",
//     },
//     {
//         id: 66,
//         rname: "Gaming Chair",
//         imgdata: gchair3 ,
//         somedata: "Our combines quality craftsmanship with modern design, making it the perfect choice for your everyday needs. Whether you’re looking for reliability, style, or value, this Product delivers all three.  it’s made to enhance your lifestyle without compromising on comfort or durability.",
//         price: 22999,
//         rating: "3.8",
//         qnty:0,
//         category:"Chair",
//     },
//     {
//         id: 66,
//         rname: "Seiling Jhumer",
//         imgdata: jhum ,
//         somedata: "Our combines quality craftsmanship with modern design, making it the perfect choice for your everyday needs. Whether you’re looking for reliability, style, or value, this Product delivers all three.  it’s made to enhance your lifestyle without compromising on comfort or durability.",
//         price: 27999,
//         rating: "3.8",
//         qnty:0,
//         category:"Jhumer",
//     },
//     {
//         id: 67,
//         rname: "Seiling Jhumer",
//         imgdata: jhum1 ,
//         somedata: "Our combines quality craftsmanship with modern design, making it the perfect choice for your everyday needs. Whether you’re looking for reliability, style, or value, this Product delivers all three.  it’s made to enhance your lifestyle without compromising on comfort or durability.",
//         price: 31999,
//         rating: "3.8",
//         qnty:0,
//         category:"Jhumer",
//     },
//     {
//         id: 68,
//         rname: "Seiling Jhumer",
//         imgdata: jhum2 ,
//         somedata: "Our combines quality craftsmanship with modern design, making it the perfect choice for your everyday needs. Whether you’re looking for reliability, style, or value, this Product delivers all three.  it’s made to enhance your lifestyle without compromising on comfort or durability.",
//         price: 34999,
//         rating: "3.8",
//         qnty:0,
//         category:"Jhumer",
//     },
//     {
//         id: 69,
//         rname: "Seiling Jhumer",
//         imgdata: jhum3 ,
//         somedata: "Our combines quality craftsmanship with modern design, making it the perfect choice for your everyday needs. Whether you’re looking for reliability, style, or value, this Product delivers all three.  it’s made to enhance your lifestyle without compromising on comfort or durability.",
//         price: 29999,
//         rating: "3.8",
//         qnty:0,
//         category:"Jhumer",
//     },
//     {
//         id: 70,
//         rname: "Seiling Jhumer",
//         imgdata: jhum4 ,
//         somedata: "Our combines quality craftsmanship with modern design, making it the perfect choice for your everyday needs. Whether you’re looking for reliability, style, or value, this Product delivers all three.  it’s made to enhance your lifestyle without compromising on comfort or durability.",
//         price: 24999,
//         rating: "3.8",
//         qnty:0,
//         category:"Jhumer",
//     },
//     {
//         id: 71,
//         rname: "Seiling Jhumer",
//         imgdata: jhum5 ,
//         somedata: "Our combines quality craftsmanship with modern design, making it the perfect choice for your everyday needs. Whether you’re looking for reliability, style, or value, this Product delivers all three.  it’s made to enhance your lifestyle without compromising on comfort or durability.",
//         price: 28999,
//         rating: "3.8",
//         qnty:0,
//         category:"Jhumer",
//     },
//     {
//         id: 72,
//         rname: "Seiling Jhumer",
//         imgdata: jhum6 ,
//         somedata: "Our combines quality craftsmanship with modern design, making it the perfect choice for your everyday needs. Whether you’re looking for reliability, style, or value, this Product delivers all three.  it’s made to enhance your lifestyle without compromising on comfort or durability.",
//         price: 31999,
//         rating: "3.8",
//         qnty:0,
//         category:"Jhumer",
//     },
//     {
//         id: 73,
//         rname: "Seiling Jhumer",
//         imgdata: jhum7 ,
//         somedata: "Our combines quality craftsmanship with modern design, making it the perfect choice for your everyday needs. Whether you’re looking for reliability, style, or value, this Product delivers all three.  it’s made to enhance your lifestyle without compromising on comfort or durability.",
//         price: 29999,
//         rating: "3.8",
//         qnty:0,
//         category:"Jhumer",
//     },
//     {
//         id: 74,
//         rname: "Seiling Jhumer",
//         imgdata: jhum8 ,
//         somedata: "Our combines quality craftsmanship with modern design, making it the perfect choice for your everyday needs. Whether you’re looking for reliability, style, or value, this Product delivers all three.  it’s made to enhance your lifestyle without compromising on comfort or durability.",
//         price: 33999,
//         rating: "3.8",
//         qnty:0,
//         category:"Jhumer",
//     },
//     {
//         id: 75,
//         rname: "Seiling Jhumer",
//         imgdata: jhum9 ,
//         somedata: "Our combines quality craftsmanship with modern design, making it the perfect choice for your everyday needs. Whether you’re looking for reliability, style, or value, this Product delivers all three.  it’s made to enhance your lifestyle without compromising on comfort or durability.",
//         price: 27999,
//         rating: "3.8",
//         qnty:0,
//         category:"Jhumer",
//     },
//     {
//         id: 76,
//         rname: "Seiling Jhumer",
//         imgdata: jhum10 ,
//         somedata: "Our combines quality craftsmanship with modern design, making it the perfect choice for your everyday needs. Whether you’re looking for reliability, style, or value, this Product delivers all three.  it’s made to enhance your lifestyle without compromising on comfort or durability.",
//         price: 24999,
//         rating: "3.8",
//         qnty:0,
//         category:"Jhumer",
//     },
//     {
//         id: 78,
//         rname: "Premium Sofa's",
//         imgdata: sofa1 ,
//         somedata: "Our combines quality craftsmanship with modern design, making it the perfect choice for your everyday needs. Whether you’re looking for reliability, style, or value, this Product delivers all three.  it’s made to enhance your lifestyle without compromising on comfort or durability.",
//         price: 24999,
//         rating: "3.8",
//         qnty:0,
//         category:"sofa",
//     },
//     {
//         id: 79,
//         rname: "Premium Sofa's",
//         imgdata: sofa2 ,
//         somedata: "Our combines quality craftsmanship with modern design, making it the perfect choice for your everyday needs. Whether you’re looking for reliability, style, or value, this Product delivers all three.  it’s made to enhance your lifestyle without compromising on comfort or durability.",
//         price: 14999,
//         rating: "3.8",
//         qnty:0,
//         category:"sofa",
//     },
//     {
//         id: 80,
//         rname: "Premium Sofa's",
//         imgdata: sofa3 ,
//         somedata: "Our combines quality craftsmanship with modern design, making it the perfect choice for your everyday needs. Whether you’re looking for reliability, style, or value, this Product delivers all three.  it’s made to enhance your lifestyle without compromising on comfort or durability.",
//         price: 17999,
//         rating: "3.8",
//         qnty:0,
//         category:"sofa",
//     },
//     {
//         id: 81,
//         rname: "Premium Sofa's",
//         imgdata: sofa4 ,
//         somedata: "Our combines quality craftsmanship with modern design, making it the perfect choice for your everyday needs. Whether you’re looking for reliability, style, or value, this Product delivers all three.  it’s made to enhance your lifestyle without compromising on comfort or durability.",
//         price: 16999,
//         rating: "3.8",
//         qnty:0,
//         category:"sofa",
//     },
//     {
//         id: 82,
//         rname: "Premium Sofa's",
//         imgdata: sofa5,
//         somedata: "Our combines quality craftsmanship with modern design, making it the perfect choice for your everyday needs. Whether you’re looking for reliability, style, or value, this Product delivers all three.  it’s made to enhance your lifestyle without compromising on comfort or durability.",
//         price: 19999,
//         rating: "3.8",
//         qnty:0,
//         category:"sofa",
//     },
//     {
//         id: 83,
//         rname: "Premium Sofa's",
//         imgdata: sofa5 ,
//         somedata: "Our combines quality craftsmanship with modern design, making it the perfect choice for your everyday needs. Whether you’re looking for reliability, style, or value, this Product delivers all three.  it’s made to enhance your lifestyle without compromising on comfort or durability.",
//         price: 21999,
//         rating: "3.8",
//         qnty:0,
//         category:"sofa",
//     },
//     {
//         id: 84,
//         rname: "Premium Sofa's",
//         imgdata: sofa6 ,
//         somedata: "Our combines quality craftsmanship with modern design, making it the perfect choice for your everyday needs. Whether you’re looking for reliability, style, or value, this Product delivers all three.  it’s made to enhance your lifestyle without compromising on comfort or durability.",
//         price: 17999,
//         rating: "3.8",
//         qnty:0,
//         category:"sofa",
//     },
//     {
//         id: 85,
//         rname: "Premium Sofa's",
//         imgdata: sofa7 ,
//         somedata: "Our combines quality craftsmanship with modern design, making it the perfect choice for your everyday needs. Whether you’re looking for reliability, style, or value, this Product delivers all three.  it’s made to enhance your lifestyle without compromising on comfort or durability.",
//         price: 13999,
//         rating: "3.8",
//         qnty:0,
//         category:"sofa",
//     },
//     {
//         id: 86,
//         rname: "Premium Sofa's",
//         imgdata: sofa8 ,
//         somedata: "Our combines quality craftsmanship with modern design, making it the perfect choice for your everyday needs. Whether you’re looking for reliability, style, or value, this Product delivers all three.  it’s made to enhance your lifestyle without compromising on comfort or durability.",
//         price: 18999,
//         rating: "3.8",
//         qnty:0,
//         category:"sofa",
//     },
//     {
//         id: 87,
//         rname: "Premium Sofa's",
//         imgdata: sofa9 ,
//         somedata: "Our combines quality craftsmanship with modern design, making it the perfect choice for your everyday needs. Whether you’re looking for reliability, style, or value, this Product delivers all three.  it’s made to enhance your lifestyle without compromising on comfort or durability.",
//         price: 17999,
//         rating: "3.8",
//         qnty:0,
//         category:"sofa",
//     },
//     {
//         id: 88,
//         rname: "Premium Sofa's",
//         imgdata: sofa10 ,
//         somedata: "Our combines quality craftsmanship with modern design, making it the perfect choice for your everyday needs. Whether you’re looking for reliability, style, or value, this Product delivers all three.  it’s made to enhance your lifestyle without compromising on comfort or durability.",
//         price: 16999,
//         rating: "3.8",
//         qnty:0,
//         category:"sofa",
//     },
//     {
//         id: 89,
//         rname: "Premium Sofa's",
//         imgdata: sofa11 ,
//         somedata: "Our combines quality craftsmanship with modern design, making it the perfect choice for your everyday needs. Whether you’re looking for reliability, style, or value, this Product delivers all three.  it’s made to enhance your lifestyle without compromising on comfort or durability.",
//         price: 17999,
//         rating: "3.8",
//         qnty:0,
//         category:"sofa",
//     },
//     {
//         id: 90,
//         rname: "Premium Sofa's",
//         imgdata: sofa12 ,
//         somedata: "Our combines quality craftsmanship with modern design, making it the perfect choice for your everyday needs. Whether you’re looking for reliability, style, or value, this Product delivers all three.  it’s made to enhance your lifestyle without compromising on comfort or durability.",
//         price: 14999,
//         rating: "3.8",
//         qnty:0,
//         category:"sofa",
//     },
//     {
//         id: 91,
//         rname: "Premium Sofa's",
//         imgdata: sofa13 ,
//         somedata: "Our combines quality craftsmanship with modern design, making it the perfect choice for your everyday needs. Whether you’re looking for reliability, style, or value, this Product delivers all three.  it’s made to enhance your lifestyle without compromising on comfort or durability.",
//         price: 17999,
//         rating: "3.8",
//         qnty:0,
//         category:"sofa",
//     },
//     {
//         id: 92,
//         rname: "Premium Sofa's",
//         imgdata: sofa14 ,
//         somedata: "Our combines quality craftsmanship with modern design, making it the perfect choice for your everyday needs. Whether you’re looking for reliability, style, or value, this Product delivers all three.  it’s made to enhance your lifestyle without compromising on comfort or durability.",
//         price: 18999,
//         rating: "3.8",
//         qnty:0,
//         category:"sofa",
//     },
//     {
//         id: 93,
//         rname: "Premium Sofa's",
//         imgdata: sofa15 ,
//         somedata: "Our combines quality craftsmanship with modern design, making it the perfect choice for your everyday needs. Whether you’re looking for reliability, style, or value, this Product delivers all three.  it’s made to enhance your lifestyle without compromising on comfort or durability.",
//         price: 14999,
//         rating: "3.8",
//         qnty:0,
//         category:"sofa",
//     },
   
// ];

// export default Cardsdata;

import L_Handle_set from "../img/bot1.avif";
import Fat_Grip_For_Cupping from "../img/bot_plast.jpg";
import Cone_Grip from "../img/bot_printed.jpg";
import Wrist_Wrench from "../img/Wrist_Wrench.png";
import Cupping_Handle from "../img/Cupping_Handle.png";
import Multi_Spinner from "../img/Multi_Spinner.png";
import TRINGA_ECCENTRIC_SET from "../img/TRINGA_ECCENTRIC_SET.png";
import Palm_Cupping_L_Handle from "../img/Palm_Cupping_L_Handle.png";
import Loading_Pin from "../img/Loading_Pin.png";
import Hand_Winder from "../img/Hand_Winder.png";
import Tiranga_Eccentric_Grip from "../img/Tiranga_Eccentric_Grip.png";
import Elliptical_Wrist_Ball from "../img/Elliptical_Wrist_Ball.png";
import Premium_Table_Strap from "../img/Premium_Table_Strap.png";
import Eccentric_Grip from "../img/Eccentric_Grip.png";
import Professional_Wrist_Ball from "../img/Professional_Wrist_Ball.png";
import Devon_Larrat_Cupping_Handles from "../img/Devon_Larrat_Cupping_Handles.png";
import Pronation_Ring_with_Hook from "../img/Pronation_Ring_with_Hook.png";
import Arm_Wrestling_Training_Strap from "../img/Arm_Wrestling_Training_Strap.png";
import Stainless_Steel_15mm_C_Handle from "../img/Stainless_Steel_15mm_C_Handle.png";
import Arm_Wrestling_Handles_Set from "../img/Arm_Wrestling_Handles_Set.png";
import Claws_Arm_Wrestling_Table from "../img/Claws_Arm_Wrestling_Table.png";
import Arm_Wrestling_Table_Pulley from "../img/Arm_Wrestling_Table_Pulley.png";
import Professional_Training_Strap from "../img/Professional_Training_Strap.png";
import Claws_Cotton_Denis_T_Shirt_Black from "../img/Claws_Cotton_Denis_T-Shirt_Black.png";
import product1 from "../img/product-1.JPG";
import cups_1 from "../img/cups_1.jpg";
import cups_2 from "../img/cups_2.jpg";
import cups_3 from "../img/cups_3.jpg";
import cups_4 from "../img/cups_4.jpg";
import cups_5 from "../img/cups_5.jpg";
import bags_1 from "../img/bags_1.jpg";
import bags_2 from "../img/bags_2.jpg";
import bags_3 from "../img/bags_3.webp";
import bags_4 from "../img/marvel.jpg";
import w1 from "../img/women/Artboard 1.jpg"
import w2 from "../img/women/Artboard 2.jpg"
import w3 from "../img/women/Artboard 3.jpg"
import w4 from "../img/women/Artboard 4.jpg"
import w5 from "../img/women/Artboard 5.jpg"
import w6 from "../img/women/Artboard 6.jpg"
import w7 from "../img/women/Artboard 7.jpg"
import w8 from "../img/women/Artboard 8.jpg"
import w9 from "../img/women/Artboard 9.jpg"
import w10 from "../img/women/Artboard 10.jpg"
import w11 from "../img/women/Artboard 11.jpg"
import w12 from "../img/women/Artboard 12.jpg"
import w13 from "../img/women/Artboard 13.jpg"
import w14 from "../img/women/Artboard 14.jpg"
import w15 from "../img/women/Artboard 15.jpg"
import a1 from "../img/products/product04.jpg"
import a2 from "../img/products/product05.jpg"
import a3 from "../img/products/product06.jpg"
import a4 from "../img/products/product08.jpg"
import a5 from "../img/products/product09.jpg"
import a6 from "../img/products/product10.jpg"
import a7 from "../img/products/product11.jpg"
import a8 from "../img/products/product12.jpg"
import a9 from "../img/products/product13.jpg"
import a10 from "../img/products/product14.jpg"
import a11 from "../img/products/product15.jpg"
import a12 from "../img/products/product16.jpg"
import a13 from "../img/products/product17.jpg"
import a14 from "../img/products/product18.jpg"
import a15 from "../img/products/product19.jpg"
import a16 from "../img/products/product20.jpg"
import a17 from "../img/products/product21.jpg"
import a18 from "../img/products/product22.jpg"
import a19 from "../img/products/product23.jpg"
import a20 from "../img/products/product24.jpg"
import a21 from "../img/products/product25.jpg"
import a22 from "../img/products/product26.jpg"
import a23 from "../img/products/product27.jpg"
import a24 from "../img/products/product28.jpg"
import a25 from "../img/products/product29.jpg"
import a26 from "../img/products/product30.jpg"
import a27 from "../img/products/product31.jpg"
import a28 from "../img/products/product32.jpg"
import a29 from "../img/products/product33.jpg"
import a30 from "../img/products/product34.jpg"
import a31 from "../img/products/product35.jpg"
import a32 from "../img/products/product36.jpg"
import a33 from "../img/products/product37.jpg"
import a34 from "../img/products/product38.jpg"
import a35 from "../img/products/product39.jpg"
import a36 from "../img/products/product40.jpg"
import a37 from "../img/products/product41.jpg"
import a38 from "../img/products/product44.jpg"





















const Cardsdata = [
   
    {
        id: 35,
        rname: "Women's outfits ",
        imgdata: w1,
        somedata: "Explore high-quality products crafted to meet your needs, offering a perfect blend of style, durability, and value.",

        price: 499,
        rating: "3.8",
        qnty:0,
        category:"dresses",
    },
   
    {
        "id": 36,
        "rname": "Women's outfits",
        "imgdata": w2,
        "somedata": "Chic and versatile, these dresses are perfect for both casual outings and special occasions.",
        "price": 4700,
        "rating": "4.2",
        "qnty": 0,
        "category": "dresses"
    },
    {
        "id": 37,
        "rname": "Women's outfits",
        "imgdata": w3,
        "somedata": "Timeless fashion pieces crafted with premium fabrics for a luxurious feel.",
        "price": 2900,
        "rating": "4.5",
        "qnty": 0,
        "category": "dresses"
    },
    {
        "id": 38,
        "rname": "Women's outfits",
        "imgdata": w4,
        "somedata": "Bold designs that empower women to express their unique style with confidence.",
        "price": 6800,
        "rating": "4.0",
        "qnty": 0,
        "category": "dresses"
    },
    {
        "id": 39,
        "rname": "Women's outfits",
        "imgdata": w5,
        "somedata": "Comfort meets fashion in these stylish dresses, perfect for every occasion.",
        "price": 5300,
        "rating": "3.9",
        "qnty": 0,
        "category": "dresses"
    },
    {
        "id": 40,
        "rname": "Women's outfits",
        "imgdata": w6,
        "somedata": "Elegant and modern, these dresses offer a sophisticated look for any wardrobe.",
        "price": 6200,
        "rating": "4.3",
        "qnty": 0,
        "category": "dresses"
    },
    {
        "id": 41,
        "rname": "Women's outfits",
        "imgdata": w7,
        "somedata": "Designed for the fashion-forward woman, these pieces are both timeless and trendy.",
        "price": 4000,
        "rating": "4.1",
        "qnty": 0,
        "category": "dresses"
    },
    {
        "id": 42,
        "rname": "Women's outfits",
        "imgdata": w8,
        "somedata": "Sophisticated, comfortable, and perfect for a wide range of occasions.",
        "price": 5800,
        "rating": "3.7",
        "qnty": 0,
        "category": "dresses"
    },
    {
        "id": 43,
        "rname": "Women's outfits",
        "imgdata": w9,
        "somedata": "Flattering silhouettes that effortlessly combine comfort with contemporary style.",
        "price": 4500,
        "rating": "4.0",
        "qnty": 0,
        "category": "dresses"
    },
    {
        "id": 44,
        "rname": "Women's outfits",
        "imgdata": w10,
        "somedata": "Effortlessly chic dresses designed to make a statement with minimal effort.",
        "price": 3100,
        "rating": "3.9",
        "qnty": 0,
        "category": "dresses"
    },
    {
        "id": 45,
        "rname": "Women's outfits",
        "imgdata": w11,
        "somedata": "Beautifully crafted with attention to detail, offering both elegance and durability.",
        "price": 6200,
        "rating": "4.4",
        "qnty": 0,
        "category": "dresses"
    },
    {
        "id": 46,
        "rname": "Women's outfits",
        "imgdata": w12,
        "somedata": "Elevate your wardrobe with these versatile and stylish women's dresses.",
        "price": 5300,
        "rating": "4.2",
        "qnty": 0,
        "category": "dresses"
    },
    {
        "id": 47,
        "rname": "Women's outfits",
        "imgdata": w13,
        "somedata": "Luxury dresses that embody both contemporary fashion and timeless elegance.",
        "price": 6500,
        "rating": "4.1",
        "qnty": 0,
        "category": "dresses"
    },
    {
        "id": 48,
        "rname": "Women's outfits",
        "imgdata": w14,
        "somedata": "High-fashion dresses designed for women who love to make a bold statement.",
        "price": 7000,
        "rating": "4.3",
        "qnty": 0,
        "category": "dresses"
    },
    {
        "id": 49,
        "rname": "Women's outfits",
        "imgdata": w15,
        "somedata": "Modern, comfortable, and effortlessly stylish dresses for every woman.",
        "price": 2900,
        "rating": "3.8",
        "qnty": 0,
        "category": "dresses"
    },
    {
        "id": 50,
        "rname": "Women's accessory",
        "imgdata": a1,
        "somedata": "Stylish and elegant accessories that add the perfect finishing touch to any outfit.",
        "price": 3400,
        "rating": "4.0",
        "qnty": 0,
        "category": "accessory"
    },
    {
        "id": 51,
        "rname": "Women's accessory",
        "imgdata": a2,
        "somedata": "Chic and modern accessories designed to complement any style with ease.",
        "price": 6200,
        "rating": "4.3",
        "qnty": 0,
        "category": "accessory"
    },
    {
        "id": 52,
        "rname": "Women's accessory",
        "imgdata": a3,
        "somedata": "Luxury accessories crafted with attention to detail, offering both elegance and durability.",
        "price": 2700,
        "rating": "3.9",
        "qnty": 0,
        "category": "accessory"
    },
    {
        "id": 53,
        "rname": "Women's accessory",
        "imgdata": a4,
        "somedata": "Timeless pieces that bring a touch of sophistication to any outfit.",
        "price": 5300,
        "rating": "4.2",
        "qnty": 0,
        "category": "accessory"
    },
    {
        "id": 54,
        "rname": "Women's accessory",
        "imgdata": a5,
        "somedata": "Beautiful accessories designed to add a unique flair to your wardrobe.",
        "price": 4800,
        "rating": "4.1",
        "qnty": 0,
        "category": "accessory"
    },
    {
        "id": 55,
        "rname": "Women's accessory",
        "imgdata": a6,
        "somedata": "Sophisticated, modern, and perfect for any occasion.",
        "price": 6700,
        "rating": "4.5",
        "qnty": 0,
        "category": "accessory"
    },
    {
        "id": 56,
        "rname": "Women's accessory",
        "imgdata": a7,
        "somedata": "Elegant accessories that perfectly balance fashion and function.",
        "price": 4500,
        "rating": "4.0",
        "qnty": 0,
        "category": "accessory"
    },
    {
        "id": 57,
        "rname": "Women's accessory",
        "imgdata": a8,
        "somedata": "Delicate yet durable accessories that enhance your everyday style.",
        "price": 5200,
        "rating": "4.2",
        "qnty": 0,
        "category": "accessory"
    },
    {
        "id": 58,
        "rname": "Women's accessory",
        "imgdata": a9,
        "somedata": "Bold and beautiful accessories that make a statement wherever you go.",
        "price": 3900,
        "rating": "3.8",
        "qnty": 0,
        "category": "accessory"
    },
    {
        "id": 59,
        "rname": "Women's accessory",
        "imgdata": a10,
        "somedata": "Handcrafted accessories designed to inspire and empower your personal style.",
        "price": 6000,
        "rating": "4.3",
        "qnty": 0,
        "category": "accessory"
    },
    {
        "id": 60,
        "rname": "Women's accessory",
        "imgdata": a11,
        "somedata": "Charming and versatile accessories that complete any outfit.",
        "price": 3200,
        "rating": "4.0",
        "qnty": 0,
        "category": "accessory"
    },
    {
        "id": 61,
        "rname": "Women's accessory",
        "imgdata": a12,
        "somedata": "Unique accessories crafted with care, perfect for enhancing any look.",
        "price": 5100,
        "rating": "3.9",
        "qnty": 0,
        "category": "accessory"
    },
    {
        "id": 62,
        "rname": "Women's accessory",
        "imgdata": a13,
        "somedata": "Effortlessly stylish accessories for the modern woman.",
        "price": 2900,
        "rating": "4.1",
        "qnty": 0,
        "category": "accessory"
    },
    {
        "id": 63,
        "rname": "Women's accessory",
        "imgdata": a14,
        "somedata": "Fashion-forward accessories that add a finishing touch to any outfit.",
        "price": 6800,
        "rating": "4.2",
        "qnty": 0,
        "category": "accessory"
    },
    {
        "id": 64,
        "rname": "Women's accessory",
        "imgdata": a15,
        "somedata": "Stylish accessories designed to elevate your everyday look.",
        "price": 3600,
        "rating": "4.0",
        "qnty": 0,
        "category": "accessory"
    },
    {
        "id": 65,
        "rname": "Women's accessory",
        "imgdata": a16,
        "somedata": "High-quality accessories crafted for durability and timeless style.",
        "price": 5400,
        "rating": "4.1",
        "qnty": 0,
        "category": "accessory"
    },
    {
        "id": 66,
        "rname": "Women's accessory",
        "imgdata": a17,
        "somedata": "Modern accessories that seamlessly blend elegance and practicality.",
        "price": 4600,
        "rating": "4.3",
        "qnty": 0,
        "category": "accessory"
    },
    {
        "id": 67,
        "rname": "Women's accessory",
        "imgdata": a18,
        "somedata": "Accessories designed to enhance and complement every style.",
        "price": 5300,
        "rating": "4.2",
        "qnty": 0,
        "category": "accessory"
    },
    {
        "id": 68,
        "rname": "Women's accessory",
        "imgdata": a19,
        "somedata": "Versatile accessories crafted to add the perfect finishing touch.",
        "price": 3700,
        "rating": "4.1",
        "qnty": 0,
        "category": "accessory"
    },
    {
        "id": 69,
        "rname": "Women's accessory",
        "imgdata": a20,
        "somedata": "Beautifully crafted accessories designed to complement any wardrobe.",
        "price": 6000,
        "rating": "4.4",
        "qnty": 0,
        "category": "accessory"
    },
    {
        "id": 70,
        "rname": "Women's accessory",
        "imgdata": a21,
        "somedata": "Fashionable accessories that effortlessly blend style and comfort.",
        "price": 4100,
        "rating": "4.0",
        "qnty": 0,
        "category": "accessory"
    },
    {
        "id": 71,
        "rname": "Women's accessory",
        "imgdata": a22,
        "somedata": "Timeless accessories for the modern woman seeking effortless elegance.",
        "price": 6900,
        "rating": "4.5",
        "qnty": 0,
        "category": "accessory"
    },
    {
        "id": 72,
        "rname": "Women's accessory",
        "imgdata": a23,
        "somedata": "Accessories that balance everyday functionality with fashion-forward style.",
        "price": 4600,
        "rating": "4.2",
        "qnty": 0,
        "category": "accessory"
    },
    {
        "id": 73,
        "rname": "Women's accessory",
        "imgdata": a24,
        "somedata": "Handcrafted accessories made with care for the modern woman.",
        "price": 5400,
        "rating": "4.1",
        "qnty": 0,
        "category": "accessory"
    },
    {
        "id": 74,
        "rname": "Women's accessory",
        "imgdata": a25,
        "somedata": "Bold and eye-catching accessories designed for women who love to stand out.",
        "price": 3200,
        "rating": "4.3",
        "qnty": 0,
        "category": "accessory"
    },
    {
        "id": 75,
        "rname": "Women's accessory",
        "imgdata": a26,
        "somedata": "Elegant and versatile accessories that suit any occasion.",
        "price": 2500,
        "rating": "3.9",
        "qnty": 0,
        "category": "accessory"
    },
    {
        "id": 76,
        "rname": "Women's accessory",
        "imgdata": a27,
        "somedata": "Beautifully designed accessories that add charm and sophistication to any outfit.",
        "price": 5800,
        "rating": "4.2",
        "qnty": 0,
        "category": "accessory"
    },
    {
        "id": 77,
        "rname": "Women's accessory",
        "imgdata": a28,
        "somedata": "Chic and fashionable accessories perfect for everyday wear or special occasions.",
        "price": 4300,
        "rating": "4.1",
        "qnty": 0,
        "category": "accessory"
    },
    {
        "id": 78,
        "rname": "Women's accessory",
        "imgdata": a29,
        "somedata": "High-quality accessories designed to last and make a statement.",
        "price": 6300,
        "rating": "4.4",
        "qnty": 0,
        "category": "accessory"
    },
    {
        "id": 79,
        "rname": "Women's accessory",
        "imgdata": a30,
        "somedata": "Affordable luxury with these stunning, carefully crafted accessories.",
        "price": 3400,
        "rating": "4.0",
        "qnty": 0,
        "category": "accessory"
    },
    {
        "id": 80,
        "rname": "Women's accessory",
        "imgdata": a31,
        "somedata": "Everyday accessories that effortlessly blend style and comfort.",
        "price": 4100,
        "rating": "4.1",
        "qnty": 0,
        "category": "accessory"
    },
    {
        "id": 81,
        "rname": "Women's accessory",
        "imgdata": a32,
        "somedata": "Timeless, handcrafted accessories designed for the modern woman.",
        "price": 6600,
        "rating": "4.3",
        "qnty": 0,
        "category": "accessory"
    },
    {
        "id": 82,
        "rname": "Women's accessory",
        "imgdata": a33,
        "somedata": "Classic accessories that never go out of style.",
        "price": 4800,
        "rating": "4.2",
        "qnty": 0,
        "category": "accessory"
    },
    {
        "id": 83,
        "rname": "Women's accessory",
        "imgdata": a34,
        "somedata": "Delicate accessories that enhance any outfit with effortless style.",
        "price": 2900,
        "rating": "4.0",
        "qnty": 0,
        "category": "accessory"
    },
    {
        "id": 84,
        "rname": "Women's accessory",
        "imgdata": a35,
        "somedata": "Elegant and stylish accessories crafted with care and attention to detail.",
        "price": 5100,
        "rating": "4.1",
        "qnty": 0,
        "category": "accessory"
    },
    {
        "id": 85,
        "rname": "Women's accessory",
        "imgdata": a36,
        "somedata": "Modern accessories that perfectly complement any look.",
        "price": 6700,
        "rating": "4.3",
        "qnty": 0,
        "category": "accessory"
    },
    {
        "id": 86,
        "rname": "Women's accessory",
        "imgdata": a37,
        "somedata": "Unique and versatile accessories for the fashion-conscious woman.",
        "price": 4300,
        "rating": "4.0",
        "qnty": 0,
        "category": "accessory"
    },
    {
        "id": 87,
        "rname": "Women's accessory",
        "imgdata": a38,
        "somedata": "Bold and beautiful accessories that enhance any style with ease.",
        "price": 3700,
        "rating": "4.1",
        "qnty": 0,
        "category": "accessory"
    }

];

export default Cardsdata;