import logo from './logo.svg';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Header from './components/Header';
import CardsDetails from './components/CardsDetails';
import Cards from './components/Cards';
import {Routes,Route} from "react-router-dom";
import Home from './components/Home';
import Footer from './components/Footer';
import Product_details from './components/Product_details';
import AboutUs from './components/AboutUs';
import TermsPolicies from './components/TermsPolicies';
import PaymentPolicies from './components/PaymentPolicies';
import PrivacyPolicy from './components/PrivacyPolicy';
import ReturnPolicy from './components/ReturnPolicy';
import ShippingPolicy from './components/ShippingPolicy';
import Forms from './components/Forms'
import PaymentSuccess from './components/PaymentSuccess'
import useScrollToTop from './components/useScrollToTop '
import ContactUs from './components/ContactUs'
import Cancel from './components/Cancel'

function App() {
  useScrollToTop();
  return (
    
  <>
   <Header />
   <Routes>
   <Route path='/' exact element={<Home />} />
     <Route path='/product_details' exact element={<Cards />} />
     <Route path='/about-us' element={<AboutUs />} />
     <Route path='/terms-policies' element={<TermsPolicies />} /> 
     {/* <Route path='/payment-policies' element={<PaymentPolicies />} /> */}
     <Route path='/privacy-policy' element={<PrivacyPolicy />} />
     <Route path='/return-policy' element={<ReturnPolicy />} />
     <Route path='/shipping-policy' element={<ShippingPolicy />} />
     <Route path='/product_details/:id' exact element={<Product_details />} />
     <Route path='/forms' element={<Forms/>} />
     <Route path='/cart/:id' element={<CardsDetails />} />
     <Route path="/payment-success" element={<PaymentSuccess />} />
     <Route path="/contact-us" element={<ContactUs/>} />
     <Route path="/cancellation-policy" element={<Cancel/>} />
   </Routes>
   <Footer />

   
  </>
  );
}

export default App;
